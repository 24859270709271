<template>
  <div class="page-content">
    <ui-page-hero :title="'Search dashboard'" />

    <div>
      <div class="has-margin-bottom">
        <MonthYearSelector
          @monthYearSelected="monthYearSelected"
          :isLoadingLeft="isLoadingLeft"
          :isLoadingRight="isLoadingRight"
          :month="Number(month)"
          :year="Number(year)"
        />
      </div>

      <div class="has-margin-bottom">
        <div class="select is-small mr-2">
          <select v-model="selectedMeetingtype">
            <option :value="0" :selected="selectedMeetingtype === 0">
              Select meetingtype
            </option>
            <option
              v-for="(meetingtype, index) in meetingtypes"
              :key="index"
              :value="meetingtype"
              :selected="meetingtype === selectedMeetingtype"
            >
              {{ meetingtype | getMeetingtypeName }}
            </option>
          </select>
        </div>

        <label class="checkbox">
          <input
            type="checkbox"
            v-model="showBackend"
            @change="getSearchQueries"
          />
          Show backend queries
        </label>
      </div>
    </div>

    <section>
      <div>
        <div class="tabs is-boxed">
          <ul>
            <li :class="{ 'is-active': showDashboard }">
              <a @click="setShowDashboard">Dashboard</a>
            </li>
            <li :class="{ 'is-active': showRawData }">
              <a @click="setShowRawData">Raw data</a>
            </li>
          </ul>
        </div>

        <div v-if="showDashboard">
          <ui-loader v-if="isLoading" />

          <div v-if="!isLoading" class="columns">
            <div class="column">
              <div>
                <div class="subtitle">Search vs. reservations</div>
                <BarChart
                  :chartdata="barChartDataset"
                  :options="barChartOptions"
                  class="barChart has-margin-bottom-x2"
                />
              </div>

              <div class="columns">
                <div class="column">
                  <div class="subtitle">Search queries per channel</div>
                  <Doughnut
                    :chartdata="channelsSearchDataset"
                    :options="doughnutOptions"
                  />
                </div>
                <div class="column">
                  <div class="subtitle">Reservations per channel</div>
                  <Doughnut
                    :chartdata="channelsReservationDataset"
                    :options="doughnutOptions"
                  />
                </div>
              </div>
            </div>

            <!-- Right column -->
            <div class="column is-one-third">
              <div class="">
                <div class="mb-5">
                  <div class="mb-2">
                    <article class="box has-text-centered">
                      <p class="title is-size-1" style="hover: hand">
                        <AnimatedNumber :value="nrOfSearch" :speed="7" />
                      </p>
                      <p class="subtitle is-size-6">Queries</p>
                    </article>
                  </div>

                  <div class="mb-2">
                    <article class="box has-text-centered">
                      <p class="title is-size-1" style="hover: hand">
                        <AnimatedNumber :value="nrOfReservations" :speed="7" />
                      </p>
                      <p class="subtitle is-size-6">Reservations</p>
                    </article>
                  </div>

                  <div class="mb-2">
                    <article class="box has-text-centered">
                      <p class="title is-size-1" style="hover: hand">
                        <AnimatedNumber :value="nrOfTenders" :speed="7" />
                      </p>
                      <p class="subtitle is-size-6">Proposal requests</p>
                    </article>
                  </div>

                  <div class="mb-2">
                    <article class="box has-text-centered">
                      <p class="title is-size-1" style="hover: hand">
                        <AnimatedNumber
                          :value="conversionPercentage"
                          :speed="7"
                        />
                      </p>
                      <p class="subtitle is-size-6">% conversion</p>
                    </article>
                  </div>
                </div>

                <div class="mt-4">
                  <SearchFunnel
                    :key="`SF-c${selectedChannel}-m${selectedMeetingtype}-w${selectedWidget}`"
                    :funnels="searchFunnelDataset"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showRawData">
          <div
            v-if="searchQueries.length > 0"
            class="is-hidden-print actionBar has-background-white-ter has-padding has-text-right mb-3"
          >
            <a
              @click="getSearchQueriesCsv"
              class="has-icon"
              :class="{ 'is-loading': isCreatingCsv }"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'file-download']" />
              </span>
              <span>Export as csvfile</span>
            </a>
          </div>

          <ui-loader v-if="isLoading" />

          <DynamicScroller
            class="scroller"
            :items="searchQueries"
            :min-item-size="32"
            key-field="Key"
            page-mode
          >
            <template #before>
              <div class="columns">
                <span class="column has-text-weight-bold">Created on</span>
                <span class="column has-text-weight-bold">Channel</span>
                <span class="column has-text-weight-bold">Source</span>
                <span class="column has-text-weight-bold">Meetingtype</span>
                <span class="column has-text-weight-bold">Date & time</span>
                <span class="column has-text-weight-bold">Start time</span>
                <span class="column has-text-weight-bold">End time</span>
                <span class="column has-text-weight-bold">Nr. of people</span>
                <span class="column has-text-weight-bold">Space</span>
                <span class="column has-text-weight-bold">Last step</span>
                <span class="column has-text-weight-bold">Reservation</span>
                <span class="column has-text-weight-bold">Is backend</span>
                <span class="column has-text-weight-bold">Search term</span>
              </div>
            </template>

            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item.WidgetName]"
                :data-index="index"
              >
                <div class="columns">
                  <span class="column">{{
                    item.CreateDate | dateObjectIsoDateString
                  }}</span>
                  <span class="column">{{ item.ChannelName }}</span>
                  <span class="column">{{ item.WidgetName }}</span>
                  <span class="column">{{ item.MeetingtypeId }}</span>
                  <span class="column">{{
                    item.StartDate | dateObjectIsoDateString
                  }}</span>
                  <span class="column">{{
                    item.StartMinutes | minutesToTime
                  }}</span>
                  <span class="column">{{
                    item.EndMinutes | minutesToTime
                  }}</span>
                  <span class="column">{{ item.Seats }}</span>
                  <span class="column">{{ item.SpaceName }}</span>
                  <span class="column">{{ item.LastStep }}</span>
                  <span class="column">{{ item.MadeReservation }}</span>
                  <span class="column">{{ item.IsBackend }}</span>
                  <span class="column">{{ item.SearchTerm }}</span>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import statsProvider from '@/providers/stats'
import { eachDayOfInterval } from 'date-fns'

export default {
  components: {
    AnimatedNumber: () => import('@/components/UI/AnimatedNumber'),
    BarChart: () => import('@/components/UI/Charts/BarChart'),
    Doughnut: () => import('@/components/UI/Charts/Doughnut'),
    MonthYearSelector: () => import('@/components/UI/MonthYearSelector'),
    SearchFunnel: () => import('@/components/Dashboards/SearchFunnel'),
  },

  props: {},

  //mixins: [staggeredAnimation],

  data() {
    return {
      isLoading: false,
      isLoadingLeft: false,
      isLoadingRight: false,
      isCreatingCsv: false,
      colourArray: ['#F44336', '#9C27B0', '#2196F3', '#8BC34A'],
      channelId: Number(this.$route.params.channelId),
      locationId: 0,
      widgetId: 0,
      startDate: new Date(),
      endDate: new Date(),
      rawData: [],
      selectedChannel: 0,
      selectedMeetingtype: 0,
      selectedWidget: 0,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      showBackend: false,
      showDashboard: true,
      showRawData: false,

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 3 / 1,
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },

      doughnutOptions: {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        cutout: '75%',
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 16 / 9,
      },
    }
  },

  computed: {
    meetingtypes() {
      let meetingtypes = [1, 2]

      return meetingtypes
    },

    datesArray() {
      let dates = []

      let baseArray = eachDayOfInterval({
        start: new Date(this.startDate),
        end: new Date(this.endDate),
      })

      for (let i = 0; i < baseArray.length; i++) {
        let date = baseArray[i]

        dates.push({
          date: date,
          isoDate: this.$options.filters.dateObjectIsoDateString(date),
        })
      }

      return dates
    },

    searchQueries() {
      let data = []

      if (!this.isLoading && this.rawData) {
        data = Object.freeze(this.rawData)
      }
      if (this.selectedChannel > 0) {
        data = data.filter((d) => d.ChannelId === this.selectedChannel)
      }
      if (this.selectedMeetingtype > 0) {
        data = data.filter((d) => d.MeetingtypeId === this.selectedMeetingtype)
      }

      return Object.freeze(data)
    },

    barChartDataset() {
      let data = {
        labels: [],
        datasets: [
          {
            label: 'Nr of reservations',
            borderWidth: 1,
            backgroundColor: '#95C11E',
            borderColor: '#95C11E',
            pointBorderColor: '#95C11E',
            data: [],
          },
          {
            label: 'Nr of search',
            borderWidth: 1,
            backgroundColor: '#00BAFF',
            borderColor: '#00BAFF',
            pointBorderColor: '#00BAFF',
            data: [],
          },
          {
            label: 'Nr of proposal requests',
            borderWidth: 1,
            backgroundColor: '#F44336',
            borderColor: '#F44336',
            pointBorderColor: '#F44336',
            data: [],
          },
        ],
      }

      data.labels = []
      data.datasets[0].data = []
      data.datasets[1].data = []
      data.datasets[2].data = []

      let dataArray = Object.freeze(this.searchQueries)

      if (!this.isLoading && dataArray) {
        for (let i = 0; i < this.datesArray.length; i++) {
          let lDate = this.datesArray[i]
          let dayData = []
          dayData = dataArray.filter((sq) => sq.CreateDateIso === lDate.isoDate)

          let dateLabel = this.$options.filters.dateFormat(
            lDate.date,
            this.$i18n.locale,
            'shortDateFormat'
          )
          let nrOfSearch = dayData.length
          let nrOfReservations = dayData.filter(
            (dd) => dd.MadeReservation > 0
          ).length
          let nrOfTenders = dayData.filter((dd) => dd.TenderId > 0).length

          data.labels.push(dateLabel)
          data.datasets[0].data.push(nrOfReservations)
          data.datasets[1].data.push(nrOfSearch)
          data.datasets[2].data.push(nrOfTenders)
        }
      }

      return data
    },

    channelsSearchDataset() {
      let data = {
        labels: [],
        datasets: [],
      }

      let dataArray = Object.freeze(this.searchQueries)

      if (!this.isLoading && dataArray) {
        let channels = []
        let labels = []
        let backgroundColors = this.colourArray
        let borderColors = this.colourArray
        let setdata = []

        for (let i = 0; i < dataArray.length; i++) {
          let sq = dataArray[i]

          let channelIndex = channels.findIndex((c) => c.Id === sq.ChannelId)
          if (channelIndex === -1) {
            channels.push({ Id: sq.ChannelId, Name: sq.ChannelName })
            labels.push(sq.ChannelName)
            setdata.push(
              dataArray.filter((s) => s.ChannelId === sq.ChannelId).length
            )
          }
        }

        data.labels = labels
        data.datasets.push({
          borderWidth: 1,
          borderColor: borderColors,
          backgroundColor: backgroundColors,
          data: setdata,
        })
      }

      return data
    },

    channelsReservationDataset() {
      let data = {
        labels: [],
        datasets: [],
      }

      let dataArray = Object.freeze(this.searchQueries)

      if (!this.isLoading && dataArray) {
        let channels = []
        let labels = []
        let backgroundColors = this.colourArray
        let borderColors = this.colourArray
        let setdata = []

        for (let i = 0; i < dataArray.length; i++) {
          let sq = dataArray[i]

          let channelIndex = channels.findIndex((c) => c.Id === sq.ChannelId)
          if (channelIndex === -1) {
            channels.push({ Id: sq.ChannelId, Name: sq.ChannelName })
            labels.push(sq.ChannelName)
            setdata.push(
              dataArray.filter(
                (s) => s.ChannelId === sq.ChannelId && s.MadeReservation > 0
              ).length
            )
          }
        }

        data.labels = labels
        data.datasets.push({
          borderWidth: 1,
          borderColor: borderColors,
          backgroundColor: backgroundColors,
          data: setdata,
        })
      }

      return data
    },

    searchFunnelDataset() {
      let funnels = []

      if (this.searchQueries && this.searchQueries.length) {
        let dataArray = Object.freeze(this.searchQueries)
        let nrOfSearch = dataArray.length

        for (let i = 1; i <= 6; i++) {
          let subSet = dataArray.filter((sq) => sq.LastStep < i)
          let entered = i === 1 ? nrOfSearch : nrOfSearch - subSet.length
          let percentage = 0
          if (nrOfSearch > 0 && entered > 0) {
            percentage = this.roundToOne((entered / nrOfSearch) * 100)
          }

          funnels.push({
            Step: i,
            Entered: entered,
            Percentage: i === 1 ? 100 : percentage,
          })
        }
      }

      return funnels
    },

    nrOfSearch() {
      return this.searchQueries.length
    },

    nrOfReservations() {
      return this.searchQueries.filter((sq) => sq.MadeReservation > 0).length
    },

    nrOfTenders() {
      return this.searchQueries.filter((sq) => sq.TenderId > 0).length
    },

    conversionPercentage() {
      let percentage = 0

      if (this.nrOfSearch > 0 && this.nrOfReservations > 0) {
        percentage = this.roundToOne(
          (this.nrOfReservations / this.nrOfSearch) * 100
        )
      }

      return percentage
    },
  },

  created() {
    this.getSearchQueries()
  },

  methods: {
    addDays(cDate, days) {
      var date = new Date(cDate)
      date.setDate(date.getDate() + days)
      return date
    },

    applySearchQueries(searchQuery) {
      this.startDate = searchQuery.startDate
      this.endDate = searchQuery.endDate

      this.getSearchQueries()
    },

    monthYearSelected(data) {
      this.month = Number(data.month)
      this.year = Number(data.year)

      this.isLoadingLeft = data.direction === 'left'
      this.isLoadingRight = data.direction === 'right'

      this.getSearchQueries()
    },

    getSearchQueries() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true
      this.rawData = []
      this.startDate = new Date(
        this.year,
        this.month === 0 ? 0 : this.month - 1,
        1
      )
      this.endDate = new Date(
        this.year,
        this.month === 0 ? 11 : this.month,
        this.month === 0 ? 31 : 0
      )

      statsProvider.methods
        .getChannelSearchQueries(
          this.locationId,
          this.$options.filters.dateObjectIsoDateString(this.startDate),
          this.$options.filters.dateObjectIsoDateString(this.endDate),
          this.channelId,
          this.widgetId,
          this.showBackend
        )
        .then((response) => {
          if (response.status === 200) {
            this.rawData = Object.freeze(response.data)
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false
          this.isLoadingLeft = false
          this.isLoadingRight = false
        })
    },

    getSearchQueriesCsv() {
      if (this.isCreatingCsv) {
        return
      }

      this.isCreatingCsv = true

      statsProvider.methods
        .getChannelSearchQueriesCsv(
          this.locationId,
          this.$options.filters.dateObjectIsoDateString(this.startDate),
          this.$options.filters.dateObjectIsoDateString(this.endDate),
          this.channelId,
          this.widgetId,
          this.showBackend
        )
        .then((response) => {
          if (response.status === 200) {
            const linkSource = `data:application/csv;base64,${response.data}`
            const csvBlob = this.dataURItoBlob(linkSource)

            /**
             * Internet Explorer stuff!
             */
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(
                csvBlob,
                `ExportSearchQueries.csv`
              )
              return
            }

            const url = window.URL.createObjectURL(csvBlob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `ExportSearchQueries.csv`)
            document.body.appendChild(link)
            link.click()

            link.remove()
            return response
          }
        })
        .finally(() => {
          this.isCreatingCsv = false
        })
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const bb = new Blob([ab], { type: mimeString })
      return bb
    },

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToOne(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+1') + 'e-1')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },

    setShowDashboard() {
      this.setVisibleItem('dashboard')
    },

    setShowRawData() {
      this.setVisibleItem('data')
    },

    setVisibleItem(item) {
      this.showDashboard = item === 'dashboard'
      this.showRawData = item === 'data'
    },
  },
}
</script>

<style></style>
