import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getQuestionStats() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/questions`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationYearRevenue(
      locationId,
      channelId,
      meetingtypeId = 0,
      year = 0
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/reservation/location`,
        {
          params: {
            channelId: channelId,
            locationId: locationId,
            meetingtypeId: meetingtypeId,
            year: year
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getCartTypeSplit(channelId, locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/cart/active`,
        {
          params: {
            channelId, channelId,
            locationId: locationId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getTransactionActivity(
      startDate,
      endDate,
      channelId = 0,
      locationId = 0,
      widgetId = 0,
      meetingtypeId = 0
    ) {

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/transaction/activity`,
        {
          params: {
            startDate: startDate,
            endDate: endDate,
            channelId: channelId,
            locationId: locationId,
            widgetId: widgetId,
            meetingtypeId: meetingtypeId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationTotalsStats(channelId = 0, countryId = 0, locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/location/totals`,
        {
          params: {
            channelId: channelId,
            countryId: countryId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationsPerYearPerMonth(
      meetingtypeId,
      channelId = 1,
      locationId = 0
    ) {
      let endpoint = "api/v1/stats/reservation"
      let meetingtype = "meetingspace"
      if (meetingtypeId === 2) {
        meetingtype = "workspace"
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/${endpoint}/${meetingtype}`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response.data
    },

    async getRevenuePerYearPerMonth(
      meetingtypeId,
      channelId = 1,
      locationId = 0
    ) {
      let endpoint = "api/v1/stats/revenue"
      let meetingtype = "meetingspace"
      if (meetingtypeId === 2) {
        meetingtype = "workspace"
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/${endpoint}/${meetingtype}`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response.data
    },

    async getSeatsPerYearPerMonth(
      meetingtypeId,
      channelId = 1,
      locationId = 0
    ) {
      let endpoint = "api/v1/stats/seats"

      let meetingtype = "meetingspace"
      if (meetingtypeId === 2) {
        meetingtype = "workspace"
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/${endpoint}/${meetingtype}`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response.data
    },

    async getFeeInvoiceTotalPerYearPerMonth(channelId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/feeinvoice`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response.data
    },

    async getSearchQueriesPerYearPerMonth(
      meetingTypeId,
      channelId = 1,
      locationId = 0
    ) {
      let endpoint = "api/v1/stats/search"
      let meetingtype = "meetingspace"
      if (meetingTypeId === 2) {
        meetingtype = "workspace"
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/${endpoint}/${meetingtype}`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response.data
    },

    async getChannelSearchQueries(locationId, startDate, endDate, channelId = 0, widgetId = 0, showBackend = false) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/search/queries`,
        {
          params: {
            locationId,
            startDate,
            endDate,
            channelId,
            widgetId,
            showBackend
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelSearchQueriesCsv(locationId, startDate, endDate, channelId = 0, widgetId = 0, showBackend = false) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/stats/search/queries/csv`,
        {
          params: {
            locationId,
            startDate,
            endDate,
            channelId,
            widgetId,
            showBackend
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
